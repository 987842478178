// export const api_base_url = 'https://headholiday.lets-go.live';
export const api_base_url = 'https://api.headholiday.com';
export const api_path = `${api_base_url}/api`;
// export const s3_base_url = 'https://head-holiday-files.s3.eu-west-2.amazonaws.com';
export const s3_base_url = 'http://head-holiday-files.s3.eu-west-2.amazonaws.com';
export const web_base_url = 'https://beta.headholiday.com';

export const fetchWithoutAuth = (url, requestOptions) => {
  requestOptions.headers = Object.assign({}, {...requestOptions.headers}, {Accept: 'application/json'});
  requestOptions = Object.assign({}, {
      method: 'GET'
    },
    {...requestOptions}
  );

  
  return new Promise((resolve, reject) =>  fetch(url, requestOptions)
  .then((response) => {
    if(response.ok)
      resolve(response.json());
    else
      reject(response.status);
  }).catch(error => {
    // console.log('FETCH ERROR', url, error);
    reject(error);
  }));

}

export const fetchWithAuth = (url, token, requestOptions) => {
  if(requestOptions.headers)
    requestOptions.headers['Authorization'] = 'Bearer '+ (token ? token : "");
  else
  requestOptions.headers = {
    Authorization: 'Bearer '+ (token ? token : "")
  };
  return fetchWithoutAuth(url, requestOptions);
};