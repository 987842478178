import {Link} from 'react-router-dom';

import {s3_base_url} from '../../../api/config';
import './styles.scss';

const PlayListBanner = (props) => {
  return (
    <div className="playlist-banner-wrapper">
      <img alt="" src={`${s3_base_url}/${props.playlist.large_image_url}`} />
      <div className="inner">
        <h2>Playlist:</h2>
        <h1>{props.playlist.name}</h1>
        <p>{props.playlist.description}</p>
        {props.firstVideo && (
          <Link className="play-all-button" to={`/player/playlist/${props.playlist.id}/${props.firstVideo}`}>Play all</Link>
        )}
      </div>
    </div>
  );
};

export default PlayListBanner;