import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../../utilities';
import classNames from 'classnames';

import {appSelectors, messageTypes} from '../../../redux/slices/appSlice';
import './styles.scss';

const Messaging = (props) => {
  const [displayMessage, setDisplayMessage] = useState(false);
  const flashMessage = useSelector(appSelectors.getFlashMessage);
  const prevMessage = usePrevious(flashMessage);
  useEffect(function() {
    if(prevMessage && flashMessage && prevMessage.messageTime && flashMessage.messageTime && prevMessage.messageTime !== flashMessage.messageTime) {
      setDisplayMessage(true);
      setTimeout(function() { setDisplayMessage(false); }, 3000);
    }
  }, [flashMessage, prevMessage]);
  
  const messageClasses = classNames(
    'flash-message-wrapper',
    {
      show: displayMessage,
      error: flashMessage && flashMessage.messageType === messageTypes.MESSAGE_ERROR,
      info: flashMessage && flashMessage.messageType === messageTypes.MESSAGE_INFO,
    }
  );

  return (
    <div className={messageClasses}>
      <h1>{flashMessage.messageTitle}</h1>
      <p>{flashMessage.messageText}</p>
    </div>
  );
  
  
};

export default Messaging;