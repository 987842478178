import {useState, useEffect, useCallback} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import BackgroundImageHeader from '../../Elements/BackgroundImageHeader';
import HeaderBar from '../../Elements/HeaderBar';
import { SendLogin, userSelectors } from '../../../redux/slices/userSlice';
import './styles.scss';

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loginEmail, updateLoginEmail] = useState('');
  const [loginPassword, updateLoginPassword] = useState('');
  const loginEmailHandler = useCallback((e) => updateLoginEmail(e.target.value), [updateLoginEmail]);
  const loginPasswordHandler = useCallback((e) => updateLoginPassword(e.target.value), [updateLoginPassword]);
  const isLoggedIn = useSelector(userSelectors.getLoggedIn);

  useEffect(() => {
    if(isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn, history]);

  const loginDataModel = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required()
  });
  
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(loginDataModel) });

  const submitLogin = useCallback(
    (loginData) => dispatch(SendLogin(loginData.email, loginData.password)),
    [dispatch]
  );
  
  return (
    <>
      <HeaderBar hideMenu={true} />
      <BackgroundImageHeader fillColour="#168986">
        <form className="login-form" onSubmit={handleSubmit(submitLogin)}>
          <div className="field-wrapper">
            <label>E-mail address</label>
            <input type="email" name="email" ref={register} onChange={loginEmailHandler} value={loginEmail} />
            {errors.email && (<p className="form-error">Please enter a valid e-mail address</p>)}
          </div>
          <div className="field-wrapper">
            <label>Password</label>
            <input type="password" name="password" ref={register} onChange={loginPasswordHandler} value={loginPassword} />
            {errors.password && (<p className="form-error">Please enter your password</p>)}
          </div>
          <div className="login-actions-wrapper">
            <button className="primary" type="submit">Login</button>
          </div>
        </form>
      </BackgroundImageHeader>
    </>
  );
};

export default Login;