import {createSlice} from '@reduxjs/toolkit';
import * as libraryFunctions from '../../api/libraryFunctions';
import {setInfoMessage, setErrorMessage} from './appSlice';

const initialState = {
  playlists: [],
  videos: []
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    updateLibraryWithPlaylist: (state, action) => {
      const libraryPlaylists = [...state.playlists];
      libraryPlaylists.push(action.payload);
      state.playlists = libraryPlaylists;
    },
    removePlaylistFromVideo: (state, action) => {
      const libraryPlaylists = [...state.playlists];
      const playlistIndex = libraryPlaylists.findIndex((playlist) => action.payload.id === playlist.id);
      if(playlistIndex !== -1) {
        libraryPlaylists.splice(playlistIndex, 1);
        state.playlists = [...libraryPlaylists];
      }
    },
    updateLibraryWithVideo: (state, action) => {
      const libraryVideos = [...state.videos];
      libraryVideos.push(action.payload);
      state.videos = libraryVideos;
    },
    removeVideoFromLibrary: (state, action) => {
      const libraryVideos = [...state.videos];
      const videoIndex = libraryVideos.findIndex((video) => action.payload.id === video.id);
      if(videoIndex !== -1) {
        libraryVideos.splice(videoIndex, 1);
        state.videos = [...libraryVideos];
      }
    },
    clearLibrary: (state, action) => {
      state.playlists = [];
      state.videos = [];
    }
  }
});

export const AddPlaylistToLibrary = (playlist) => {
  return (dispatch, getState) => {
    if(playlist && playlist.id && playlist.name && !libraryFunctions.libraryContainsPlaylist(librarySelectors.getLibraryPlaylists(getState()), playlist.id)) {
      dispatch(librarySlice.actions.updateLibraryWithPlaylist(playlist));
      dispatch(setInfoMessage({messageText: `Successfully added ${playlist.name} to your library`}));
    }
    else {
      dispatch(setErrorMessage({messageText: 'This playlist is already in your library'}))
    }
  };
}

export const RemovePlaylistFromLibrary = (playlist) => {
  return (dispatch, getState) => {
    if(playlist && playlist.id && playlist.name && libraryFunctions.libraryContainsPlaylist(librarySelectors.getLibraryPlaylists(getState()), playlist.id)) {
      dispatch(librarySlice.actions.removePlaylistFromVideo(playlist));
      dispatch(setInfoMessage({messageText: `Successfully removed ${playlist.name} from your library`}));
    }
    else {
      dispatch(setErrorMessage({messageText: 'This playlist could not be found in your library'}))
    }
  };
}

export const AddVideoToLibrary = (video) => {
  return (dispatch, getState) => {
    if(video && video.id && video.name && !libraryFunctions.libraryContainsVideo(librarySelectors.getLibraryVideos(getState()), video.id)) {
      dispatch(librarySlice.actions.updateLibraryWithVideo(video));
      dispatch(setInfoMessage({messageText: `Successfully added ${video.name} to your library`}));
    }
    else {
      dispatch(setErrorMessage({messageText: 'This video is already in your library.'}));
    }
  };
}

export const RemoveVideoFromLibrary = (video) => {
  return (dispatch, getState) => {
    if(video && video.id && video.name && libraryFunctions.libraryContainsVideo(librarySelectors.getLibraryVideos(getState()), video.id)) {
      dispatch(librarySlice.actions.removeVideoFromLibrary(video));
      dispatch(setInfoMessage({messageText: `Successfully removed ${video.name} from your library`}));
    }
    else {
      dispatch(setErrorMessage({messageText: 'This video couldn\'t be found in your library.'}));
    }
  };
}

export const librarySelectors = {
  getLibraryPlaylists: (state) => {
    const playlists = [...state.libraryReducer.playlists];
    return playlists.map((playlist) => {return {...playlist, in_library: true}});
  },
  getLibraryVideos: (state) => {
    const videos = [...state.libraryReducer.videos];
    return videos.map((video) => {return {...video, in_library: true}});
  }
}

export const {clearLibrary} = librarySlice.actions;

export default librarySlice.reducer;