import {useEffect, useRef, useCallback} from 'react';
import throttle from 'lodash.throttle';
import * as appActions from '../redux/slices/appSlice'
import {setLoggedOut, userSelectors} from '../redux/slices/userSlice';

export const createDoubleRowLists = (list) => {
  const listColumns = [];
  for(let i=0; i<list.length; i+=2) {
    const column = [];
    let id = '';
    for(let j=i; j<i+2&&j<list.length;j++) {
      column.push(list[j]);
      id += list[j].id ? `${list[j].id}` : `${j}`;
    }
    listColumns.push({
      id,
      column
    });
  }
  return listColumns;
}

// Function to carry out common structure of async fetch actions that require auth
export const FetchActionWithAuth = (dispatch, getState, fetchAction, params, successAction, errorAction, errorMessage) => {
  const token = userSelectors.getToken(getState());   // Get the user token.  If it's not set, something has gone wrong and we need to log the user out.
  if(!token) {
    dispatch(appActions.setErrorMessage('Sorry, you\'ve been logged out.  You may have logged in on another device'));
    dispatch(setLoggedOut());
    return;
  }
  dispatch(appActions.startFetch());  // Show the loading screen
  fetchAction(token, params).then((response) => {
    if(successAction) dispatch(successAction(response));
  }).catch((error) => {
    if(error.code && error.code === 401) {
      dispatch(setLoggedOut());
      dispatch(appActions.setErrorMessage('Sorry, you\'ve been logged out.  You may have logged in on another device'));
    }
    else {
      if(errorAction) dispatch(errorAction(error));
      if(errorMessage) dispatch(appActions.setErrorMessage({
        messageText: errorMessage
      }));
    }
  }).finally(() => {
    dispatch(appActions.stopFetch());
  });
}

export const FetchActionWithAuthSilent = (dispatch, getState, fetchAction, params, successAction, errorAction) =>{
  const token = userSelectors.getToken(getState());   // Get the user token.  If it's not set, fail silentlty
  if(token) {
    fetchAction(token, params).then((response) => {
      if(successAction) dispatch(successAction(response));
      return response;
    }).catch((error) => {
      if(errorAction) dispatch(errorAction(error));
      return error;
    });
  }

}

export const slugify = (text, separator = "-") => {
  return text
    .toString()
    .normalize('NFD')                   // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useDidMount = () => {
  const didMountRef = useRef(true);
  useEffect(() => {
    didMountRef.current = false;
  }, []);
  return didMountRef.current;
};

export const useThrottle = (callback, delay) => {	
  return useCallback(		
    throttle(
      (...args) => callback(...args), delay),
      [delay], 
    );	
}

export const  zeroFill = ( number, width ) => {
  width -= number.toString().length;
  if ( width > 0 )
    return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
  return number + ""; // always return a string
}