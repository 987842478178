import * as apiConfig from './config';

export const login = (email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email, password})
  };
  return new Promise((resolve, reject) => {
    apiConfig.fetchWithoutAuth(apiConfig.api_path + '/remoteAuth', requestOptions)
    .then((response) => {
      return resolve(response);
    }).catch((error) => {
      return reject({
        code: error,
        message: error === 401 ? 'Sorry, we couldn\'t find an account with that email and password.  Please try again' : 'Sorry, we couldn\'t log you in right now.'
      });
    });
  });
}

export const sendFeedback = (token, feedback) => {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({feedback})
  };
  return new Promise((resolve, reject) => {
    apiConfig.fetchWithAuth(apiConfig.api_path + '/feedback', token, requestOptions)
    .then((response) => {
      return resolve(response);
    }).catch((error) => {
      return reject({
        code: error,
        message: 'Something went wrong posting your feedback.'
      });
    });
  });
}