import {createSlice} from '@reduxjs/toolkit';

export const messageTypes = {
  MESSAGE_ERROR: 1,
  MESSAGE_INFO: 2,
};

const initialState = {
  fetching: 0,
  error: {},
  message: {
    messageTime: -1
  },
  currentPlaylist: [],
  numberOfPlays: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startFetch: (state, action) => {
      state.fetching = state.fetching + 1;
    },
    stopFetch: (state, action) => {
      state.fetching = state.fetching - 1;
    },
    resetFetching: (state, action) => {
      state.fetching = 0;
    },
    setErrorMessage: (state, action) => {
      state.message = {
        messageTitle: 'Oops, something went wrong',
        messageText: action.payload.errorText,
        messageType: messageTypes.MESSAGE_ERROR,
        messageTime: Date.now()
      };
    },
    setInfoMessage: (state, action) => {
      state.message = {
        messageTitle: action.payload.messageText,
        messageText: null,
        messageType: messageTypes.MESSAGE_INFO,
        messageTime: Date.now()
      }
    },
    incrementNumberOfPlays: (state, action) => {
      state.numberOfPlays = state.numberOfPlays + 1;
    },
    resetNumberOfPlays: (state, action) => {
      state.numberOfPlays = 0;
    }

  }
});

export const appSelectors = {
  getFetching: (state) => {
    return state.appReducer.fetching;
  },
  getFlashMessage: (state) => {
    return state.appReducer.message;
  },
  getNumberOfPlays: (state) => {
    return state.appReducer.numberOfPlays;
  }
};

export const {startFetch, stopFetch, resetFetching, setErrorMessage, setInfoMessage, incrementNumberOfPlays, resetNumberOfPlays} = appSlice.actions;
export default appSlice.reducer;