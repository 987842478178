import addButtonIcon from '../../../assets/images/icon-add-button.png';
import minusButtonIcon from '../../../assets/images/icon-minus-button.png';
import backButtonIcon from '../../../assets/images/icon-back-button.png';
import './styles.scss';

const Button = (props) => {
  return (<div className="button-wrapper" >
            <img alt={props.altText} src={props.image} />
          </div>);
};

export const AddButton = (props) => <Button {...props} image={addButtonIcon} altText="Add" />
export const RemoveButton = (props) => <Button {...props} image={minusButtonIcon}  altText="Remove" />
export const BackButton = (props) => <Button {...props} image={backButtonIcon}  altText="Back" />
export const NoButton = (props) => null;