import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { userSelectors } from '../../../redux/slices/userSlice';

const LoginGate = (props) => {
  const history = useHistory();
  const isLoggedIn = useSelector(userSelectors.getLoggedIn);
  
  useEffect(() => {
    if(!isLoggedIn) {
      history.push(props.loginPath);
    } else {
      
    }
  }, [isLoggedIn, history, props]);

  return (<></>);
};

export default LoginGate;