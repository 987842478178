import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {useThrottle} from '../../../utilities';
import {FetchAutocompleteSuggestions, clearAutocompleteSuggestions, catalogueSelectors} from '../../../redux/slices/catalogueSlice';
import searchIcon from '../../../assets/images/icon-search.svg';
import './styles.scss';

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const autocompleteSuggestions = useSelector(catalogueSelectors.getAutocompleteSuggestions);
  const throttledSearchHandler = useThrottle((searchTerm) => {
    dispatch(FetchAutocompleteSuggestions(searchTerm));
  }, 500);
  

  const handleSearchUpdate = event => {
    const { value: nextValue } = event.target;
    setSearchTerm(nextValue);
    if(nextValue.length > 2)
      throttledSearchHandler(nextValue);
    else {
      dispatch(clearAutocompleteSuggestions())
    }
  }

  const handleSearch = useCallback(() => {
    history.push(`/search/${searchTerm}`);
  }, [ history, searchTerm]); 

  const handleSearchKeyPress = (event) => {
    if(event.keyCode === 13) handleSearch();
  };


  return (
    <div className="search-bar-container">
    <input value={searchTerm} onKeyUp={handleSearchKeyPress} onChange={handleSearchUpdate}  className="search-bar-input" />
      <button className="search-bar-button" onClick={handleSearch}>
        <img alt="search" className="search-bar-button-icon" src={searchIcon} />
      </button>
      {autocompleteSuggestions && autocompleteSuggestions.length > 0 && (
      <ul className="search-bar-autocomplete">
        {autocompleteSuggestions.map((suggestion, index) => {
          return <li className="search-bar-autocomplete-item" key={`suggestion-${index}`}><Link to={`/search/${suggestion.name}`}>{suggestion.name}</Link></li>
        })}
      </ul>)}
    </div>
  );
};

export default SearchBar; 