import {createSlice} from '@reduxjs/toolkit';

import {librarySelectors} from './librarySlice';
import {addVideosToStack} from './playerSlice';
import * as catalogueFunctions from '../../api/catalogueFunctions';
import * as libraryFunctions from '../../api/libraryFunctions';
import {FetchActionWithAuth, FetchActionWithAuthSilent, slugify} from '../../utilities';

const initialState = {
  homeCollections: [],
  playlists: [],
  videos: [],
  playlistVideos: [],
  searchResults: {
    playlists: [],
    videos: [],
  },
  searchTerm: undefined,
  autoCompleteSuggestions: [],
  currentVideoDetails: {}
};

const catalogueSlice = createSlice({
  name: 'catalogue',
  initialState,
  reducers: {
    setHomeCollections: (state, action) => {
      state.homeCollections = action.payload.collections;
    },
    setPlaylists: (state, action) => {
      state.playlists = action.payload.playlists;
    },
    setVideos: (state, action) => {
      state.videos = action.payload.videos;
    },
    setCurrentVideoDetail: (state, action) => {
      if(action.payload.videos.length === 1)
        state.currentVideoDetails = action.payload.videos[0];
    },
    setPlaylistVideos: (state, action) => {
      const {playlistsData} = action.payload;
      if(
        playlistsData &&
        playlistsData.playlists && 
        Array.isArray(playlistsData.playlists) && 
        playlistsData.playlists.length === 1 &&
        playlistsData.playlists[0].all_videos)
          state.playlistVideos = playlistsData.playlists[0].all_videos;
      else
        state.playlistVideos = [];
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    clearSearchResults: (state, action) => {
      state.searchResults = {
        playlists: [],
        videos: [],
      };
    },
    setAutocompleteSuggestions: (state, action) => {
      state.autoCompleteSuggestions = [...action.payload];
    },
    clearAutocompleteSuggestions: (state, action) => {
      state.autoCompleteSuggestions = [];
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    clearSearchTerm: (state, action) => {
      state.searchTerm = undefined;
    }

  }
});



export const FetchHomeCollections = () => {
  return (dispatch, getState) => {
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchHomeCollections, {}, catalogueSlice.actions.setHomeCollections, null, 'Failed to load home page collections');
  }
}
// Get the first 50 videos
// TODO actual paging
export const FetchVideos = () => {
  return (dispatch, getState) => {
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchVideos, {}, catalogueSlice.actions.setVideos, null, 'Failed to load videos');
  }
};

export const FetchCurrentVideoDetails = (currentVideoId) => {
  return (dispatch, getState) => {
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchVideo, {videoId: currentVideoId}, catalogueSlice.actions.setCurrentVideoDetail, null, 'Failed to load video');
  }
}

export const FetchPlaylists = () => {
  return (dispatch, getState) => {
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchPlaylists, {}, catalogueSlice.actions.setPlaylists, null, 'Failed to load playlist data');
  }
};

export const FetchPlaylistVideos = (playlistId) => {
  return (dispatch, getState) => {
    dispatch(catalogueSlice.actions.setPlaylistVideos({playlistId}));  // clear the playlist
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchPlaylistVideos, {playlistId}, (playlistsData) => {
      return (dispatch, getState) => {
        dispatch(catalogueSlice.actions.setPlaylistVideos({playlistId, playlistsData}));
        if(playlistsData &&
        playlistsData.playlists && 
        Array.isArray(playlistsData.playlists) && 
        playlistsData.playlists.length === 1 &&
        playlistsData.playlists[0].all_videos)
          dispatch(addVideosToStack(playlistsData.playlists[0].all_videos.map((video) => { return {id: video.id, url: video.url}})));
      }
    }, null, 'Failed to load playlist videos');
  }
}

export const FetchSearchResults = (searchTerm) => {
  return (dispatch, getState) => {
    dispatch(catalogueSlice.actions.setSearchTerm(searchTerm));
    dispatch(catalogueSlice.actions.clearAutocompleteSuggestions());
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchSearchResults, {searchTerm}, catalogueSlice.actions.setSearchResults, null, 'Failed to get search results');
  }
}

export const FetchAutocompleteSuggestions = (searchTerm) => {
  return (dispatch, getState) => {
    return FetchActionWithAuthSilent(dispatch, getState, catalogueFunctions.fetchAutocompleteSuggestions, {searchTerm}, catalogueSlice.actions.setAutocompleteSuggestions, null);
  }
}

export const catalogueSelectors = {
  getHomeCollections: (state) => {
    if(!state.catalogueReducer.homeCollections || state.catalogueReducer.homeCollections.length === 0) return [];
    const homeCollections = state.catalogueReducer.homeCollections.map((collection) => {
      const playlists =  collection.playlists.map((playlist) => {
        return {
          ...playlist,
          in_library: libraryFunctions.libraryContainsPlaylist(librarySelectors.getLibraryPlaylists(state), playlist.id),
          slug: slugify(playlist.name)
        };
      });
      return {...collection, playlists};
    });
    return homeCollections;
  },
  getPlaylists: (state) => {
    if(!state.catalogueReducer.playlists) return [];
    const playlists = state.catalogueReducer.playlists.map((playlist) => {
      return {
        ...playlist,
        in_library: libraryFunctions.libraryContainsPlaylist(librarySelectors.getLibraryPlaylists(state), playlist.id),
        slug: slugify(playlist.name)
      };
    });
    return playlists;
  },
  getVideos: (state) => { // This gets a full list of the videos
    if(!state.catalogueReducer.videos || state.catalogueReducer.videos.length === 0) return [];
    const videos = state.catalogueReducer.videos.map((video) => {
      return {
        ...video,
        in_library: libraryFunctions.libraryContainsVideo(librarySelectors.getLibraryVideos(state), video.id)
      };
    });
    return videos;
  },
  getCurrentVideoDetails: (state) => {
    return state.catalogueReducer.currentVideoDetails;
  },
  getPlaylistVideos: (state) => {
    if(!state.catalogueReducer.playlistVideos || state.catalogueReducer.playlistVideos.length === 0) return [];
    return state.catalogueReducer.playlistVideos;
  },
  getSearchResults: (state) => {
    const {playlists, videos} = state.catalogueReducer.searchResults;
    const libraryPlaylists = librarySelectors.getLibraryPlaylists(state);
    const libraryVideos = librarySelectors.getLibraryVideos(state);
    const searchResults = {
      playlists: playlists.map(playlist => {
        return {
          ...playlist,
          in_library: libraryFunctions.libraryContainsPlaylist(libraryPlaylists, playlist.id),
          slug: slugify(playlist.name)
        };
      }),
      videos: videos.map(video => {
        return {
          ...video,
          in_library: libraryFunctions.libraryContainsVideo(libraryVideos, video.id)
        };
      })
    };
    return searchResults;
  },
  getSearchTerm: (state) => {
    return state.catalogueReducer.searchTerm;
  },
  getAutocompleteSuggestions: (state) => {
    return state.catalogueReducer.autoCompleteSuggestions;
  }
}

export const {clearSearchResults, clearAutocompleteSuggestions, setSearchTerm, clearSearchTerm} = catalogueSlice.actions;

export default catalogueSlice.reducer;