import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'

import Messaging from './components/Elements/Messaging';
import Home from './components/Home';
import BrowseScreen from './components/BrowseScreen';
import Login from './components/Account/Login';
import PlaylistScreen from './components/PlaylistScreen';
import PlayerScreen from './components/PlayerScreen';
import SearchScreen from './components/SearchScreen';
import './styles/App.scss';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/browse" component={BrowseScreen} />
              <Route path="/login" component={Login} />
              <Route path="/playlist/:slug" component={PlaylistScreen} />
              <Route path="/player/playlist/:playlistid/:videoid?" component={PlayerScreen} />
              <Route path="/player/video/:videoid" component={PlayerScreen} />
              <Route path="/search/:term" component={SearchScreen} />
            </Switch>
          </Router>
          <Messaging />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
