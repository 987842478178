import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { catalogueSelectors, FetchPlaylistVideos } from '../../redux/slices/catalogueSlice';
import { userSelectors } from '../../redux/slices/userSlice';
import { useDidMount } from '../../utilities';
import LoginGate from '../Elements/LoginGate';
import HeaderBar from '../Elements/HeaderBar';
import PlayListBanner from '../Elements/PlaylistBanner';
import VideoTile from '../Elements/Tiles/VideoTile';
import './styles.scss';


const PlaylistScreen = (props) => {
  const dispatch = useDispatch();
  const didMount = useDidMount();
  const isLoggedIn = useSelector(userSelectors.getLoggedIn);
  const playlists = useSelector(catalogueSelectors.getPlaylists);
  const playlistVideos = useSelector(catalogueSelectors.getPlaylistVideos);
  const selectedPlaylist = playlists.find((playlist) => playlist.slug === props.match.params.slug);
  useEffect(() => {
    if(isLoggedIn && didMount && selectedPlaylist) {
      dispatch(FetchPlaylistVideos(selectedPlaylist.id));
    }
  }, [isLoggedIn, didMount, selectedPlaylist, dispatch]);

  const firstVideo = (playlistVideos && playlistVideos.length > 0) ? playlistVideos[0].id : undefined;

  return (
    <>
      <LoginGate loginPath="/login" />
        {!selectedPlaylist && (<div>No selected playlist</div>) }
        {selectedPlaylist && playlistVideos && isLoggedIn && (
          <>
            <HeaderBar colour="purple" />
            <PlayListBanner playlist={selectedPlaylist} firstVideo={firstVideo}/>
            <div className="playlist-video-wrapper">
              {playlistVideos.map(video => {
                return <VideoTile type="playlist" playlistId={selectedPlaylist.id} {...video} />;
              })}
            </div>
          </>
        )}
    </>
  );

};

export default PlaylistScreen;