import { Link } from 'react-router-dom';

import {s3_base_url} from '../../../../api/config';
import './styles.scss';

const GenreTile = (props) => {
  return (
    <Link className="genre-playlist-tile" to={{pathname: `/playlist/${props.item.slug}`, state: {...props.item}}}>
      <div className="tile-wrapper">
        <img alt="" className="tile-background" src={`${s3_base_url}/${props.item.lozenge_image_url}`} />
        <div className="playlist-content">
          <p className="playlist-name">{props.item.name}</p>
          <p className="playlist-count">{(props.item.all_video_count || 0) + ' videos'}</p>
        </div>
      </div>
    </Link>
  )
}

export default GenreTile;