import { Link } from 'react-router-dom';

import { s3_base_url } from '../../../../api/config';
import './styles.scss';

const VideoTile = (props) => {
  const url = `/player/${props.type}/${props.type === 'playlist' ? props.playlistId + '/' + props.id : props.id }`
  return (
    <Link className="video-tile-wrapper" to={{pathname: url, state: {...props}}} >
      <div className="video-tile">
        <div className="video-tile-image-wrapper">
          <img alt={props.name} src={`${s3_base_url}/${props.thumbnail_url}`} />
        </div>
        <div className="video-tile-details">
          <h2>{props.name}</h2>
          <p>by {props.author}</p>
        </div>
      </div>
    </Link>
  );
}

export default VideoTile;