import classNames from 'classnames';
import {Link, useLocation} from 'react-router-dom';

import logo from '../../../assets/images/logo.svg';
import './styles.scss';

const HeaderBar = (props) => {
  const wrapperClasses = classNames(
    'header-bar',
    {
      pink: props.colour === 'pink',
      purple: props.colour === 'purple',
    }
  );
  const location = useLocation();

  return (
    <div className={wrapperClasses}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Head Holiday" />
        </Link>
      </div>
      {!props.hideMenu && (
        <div className="menu-wrapper">
          <Link className={location.pathname === "/" ? 'active' : '' } to="/">Explore</Link>
          <Link className={location.pathname === "/browse" ? 'active' : '' } to="/browse">Browse</Link>
        </div>
      )}
      <div className="hamburger"></div>
    </div>
  );
}

export default HeaderBar;