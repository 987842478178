import * as apiConfig from './config';

export const fetchHomeCollections = (token) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    apiConfig.fetchWithAuth(apiConfig.api_path + '/collections/homepage', token, requestOptions)
    .then(response => {
      return resolve(response);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Couldn\'t fetch catalogue from server'
      });
    });
  })
};

export const fetchPlaylists = (token) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    apiConfig.fetchWithAuth(apiConfig.api_path + '/playlists', token, requestOptions)
    .then(response => {
      return resolve(response);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Couldn\'t fetch playlists from server'
      });
    });
  });
}

export const fetchVideos = (token) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    apiConfig.fetchWithAuth(apiConfig.api_path + '/videos/latest/0/100', token, requestOptions)
    .then(response => {
      return resolve(response);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Couldn\'t fetch catalogue from server'
      });
    });
  })
};

export const fetchVideo = (token, {videoId}) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise ((resolve, reject) => {
    apiConfig.fetchWithAuth(apiConfig.api_path + `/video/${videoId}`, token, requestOptions)
    .then(response => {
      resolve(response);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Could not find the requested video'
      });
    });
  });
};

export const fetchVideoUrl = (token, videoId) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    apiConfig.fetchWithAuth(apiConfig.api_path + `/video/${videoId}/url`, token, requestOptions)
    .then(response => 
      resolve(response)
    ).catch(error => {
      return reject({
        code: error,
        message: 'Could not fetch video url'
      });
    });
  });
};

export const fetchPlaylistVideos = (token, params) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    return apiConfig.fetchWithAuth(apiConfig.api_path + `/playlist/${params.playlistId}`, token, requestOptions)
    .then(response => {
      return resolve(response);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Couldn\'t fetch playlist videos'
      });
    });
  });
};

export const fetchSearchResults = (token, params) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    return apiConfig.fetchWithAuth(apiConfig.api_path + `/search/${params.searchTerm}`, token, requestOptions)
    .then((response) => {
      return resolve(response);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Couldn\'t fetch search results'
      });
    });
  });
};

export const fetchAutocompleteSuggestions = (token, params) => {
  const requestOptions = {
    method: 'GET'
  };
  return new Promise((resolve, reject) => {
    return apiConfig.fetchWithAuth(apiConfig.api_path + `/autocomplete/${params.searchTerm}`, token, requestOptions)
    .then((response) => {
      return resolve(response.tags);
    }).catch(error => {
      return reject({
        code: error,
        message: 'Couldn\'t fetch autocomplete results'
      });
    });
  });
};