import classNames from 'classnames';

import VideoTile from '../Tiles/VideoTile';
import './styles.scss';

const Playlist = (props) => {
  const {playlistVideos, playlistId, currentVideoIndex} = props;
  return (
    <ol class="playlist-wrapper">

      {playlistVideos.map((video, index) => {
        const playlistItemClasses = classNames(
          'playlist-item', 
          {
            current: index === currentVideoIndex
          }
        );
        return (<li className={playlistItemClasses}  ><VideoTile type="playlist" playlistId={playlistId} {...video} /></li>);
        
      })}
    </ol>
  );
};

export default Playlist;