import {createSlice} from '@reduxjs/toolkit';
// import * as appActions from './appSlice';
// import {userSelectors} from './userSlice';
import {FetchActionWithAuth} from '../../utilities';
import * as catalogueFunctions from '../../api/catalogueFunctions';

const initialState = {
  currentPlayerIndex: 0,
  videoStack: [],
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    addVideosToStack: (state, action) => {
      state.videoStack = action.payload
      state.currentPlayerIndex = 0;
    },
    addVideoToStack: (state, action) => {
      state.videoStack = [action.payload];
      state.currentPlayerIndex = 0;
    },
    clearStack: (state, action) => {
      state.videoStack = [];
      state.currentPlayerIndex = 0;
    }
  }
});

// export const FetchVideoUrls = (videoIds) => {
//   return (dispatch, getState) => {
//     dispatch(playerSlice.actions.clearStack())
//     return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchVideoUrls, {videoIds}, playerSlice.actions.addVideosToStack, null, 'Could not get videos from server');
//   };
// }

export const CreateStackFromId = (videoId) => {
  return (dispatch, getState) => {
    return FetchActionWithAuth(dispatch, getState, catalogueFunctions.fetchVideoUrl, videoId, playerSlice.actions.addVideoToStack, null, 'Could not load videos');
  }
}
// export const CreateStackFromIds = (videosIds) => {
//   return (dispatch, getState) => {
//     dispatch(playerSlice.actions.clearStack())
//     dispatch(appActions.startFetch());
//     catalogueFunctions.fetchVideoUrls(userSelectors.getToken(getState()), videosIds)
//     .then(responses => {
//       dispatch(playerSlice.actions.addVideosToStack(responses));
//     }).catch(error => { 
//       dispatch(appActions.setErrorMessage('Could not load videos'));
//     }).finally(() => {
//       dispatch(appActions.stopFetch());
//     });
//   };
// }

export const playerSelectors = {
  getVideoStack: (state) => state.playerReducer.videoStack,
  getCurrentPlayerIndex: (state) => state.playerReducer.currentPlayerIndex,
}

export const {addVideosToStack, clearStack} = playerSlice.actions;
export default playerSlice.reducer;