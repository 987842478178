import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import LoginGate from '../Elements/LoginGate';
import HeaderBar from '../Elements/HeaderBar';
import SearchBar from '../Elements/SearchBar';
import FeaturedPlaylistTile from '../Elements/Tiles/FeaturedPlaylistTile';
import VideoTile from '../Elements/Tiles/VideoTile';
import {useDidMount} from '../../utilities';
import {userSelectors} from '../../redux/slices/userSlice';
import {FetchSearchResults, clearAutocompleteSuggestions, catalogueSelectors} from '../../redux/slices/catalogueSlice';
import './styles.scss';

const SearchScreen = (props) => {
  const isLoggedIn = useSelector(userSelectors.getLoggedIn);
  const dispatch = useDispatch();
  const didMount = useDidMount();
  const urlParams = useParams();
  const searchResults = useSelector(catalogueSelectors.getSearchResults);
  const searchTerm = useSelector(catalogueSelectors.getSearchTerm);

  useEffect(() => {
    if(didMount)
      dispatch(clearAutocompleteSuggestions());
  }, [didMount, dispatch]);

  useEffect(() => {
    if(urlParams.hasOwnProperty('term')) 
      dispatch(FetchSearchResults(urlParams.term));
  }, [didMount, dispatch, urlParams]);

  return (
    <>
      <LoginGate loginPath="/login" />
      {isLoggedIn && (
        <>
          <HeaderBar />
          <div className="search-bar-wrapper">
            <SearchBar />
          </div>
          {searchTerm && (
            <div className="search-results-term">
              <h2>Showing results for {searchTerm}</h2>
            </div>
          )}
          <div className="search-results-playlist search-results-container">
            <h2>Playlists:</h2>
            <div className="search-results-tiles search-results-playlists-tiles">
              {searchResults.playlists.map((playlist, tileIndex) => {
                return <FeaturedPlaylistTile key={`playlist-tile-${tileIndex}`} item={playlist} />;
              })}
            </div>
          </div>
          <div className="search-results-videos search-results-container">
            <h2>Videos:</h2>
            <div className="search-results-tiles search-results-videos-tiles">
              {searchResults.videos.map((video, tileIndex) => {
                return <VideoTile key={`video-tile-${tileIndex}`} type="video" {...video} />;
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchScreen;