import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoginGate from '../Elements/LoginGate';
import BackgroundImageHeader from '../Elements/BackgroundImageHeader';
import HeaderBar from '../Elements/HeaderBar';
import SearchBar from '../Elements/SearchBar';
import VideoTile from '../Elements/Tiles/VideoTile';
import { useDidMount } from '../../utilities';
import { userSelectors } from '../../redux/slices/userSlice';
import { FetchVideos, catalogueSelectors } from '../../redux/slices/catalogueSlice';
import './styles.scss';


const BrowseScreen = (props) => {
  const isLoggedIn = useSelector(userSelectors.getLoggedIn);
  const videos = useSelector(catalogueSelectors.getVideos);
  const didMount = useDidMount();
  const dispatch= useDispatch();

  useEffect(() => {
    if(didMount && isLoggedIn) {
      dispatch(FetchVideos());
    }
  }, [didMount, isLoggedIn, dispatch]);

  return (
    <>
      <LoginGate loginPath="/login" />
      {isLoggedIn && (
        <>
          <HeaderBar />
          <BackgroundImageHeader>
            <SearchBar />
            <div className="intro-text">
              <p>Head Holiday is a smartphone app (and website) that uses 360 degree videos to change your mood, entertain you, and give you lots of travel ideas.</p>
              <p>Created by a crack team of cultural producers, videographers and travellers, Head Holiday mixes elements of environmental psychology, slow cinema and vlogging. </p>
            </div>
          </BackgroundImageHeader>
          <div className="browse-page-video-list">
            {videos && videos.map((video, videoIndex) => {
              return <VideoTile key={`video-${video.id}`} type="video" {...video} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default BrowseScreen;