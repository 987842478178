import { useState, useEffect, useCallback } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Scrubber } from 'react-scrubber';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { useDidMount, zeroFill } from '../../../utilities';
import { s3_base_url } from '../../../api/config';
import startButton from '../../../assets/images/icon-play-active.svg';
import playButton from '../../../assets/images/button-play.svg';
import pauseButton from '../../../assets/images/button-pause.svg';
import fullscreenButton from '../../../assets/images/button-full-screen.svg';
import loading1 from '../../../assets/images/loading-1.gif';
import loading2 from '../../../assets/images/loading-2.gif'
import loading3 from '../../../assets/images/loading-3.gif'
import './styles.scss';

const Player = (props) => {
  const didMount = useDidMount();
  const handle = useFullScreenHandle();
  const [uuid,] = useState(uuidv4());
  const [playerMode, setPlayerMode] = useState(props.autoPlay ? 'player' : 'cover');
  const [videoElement, setVideoElement] = useState(undefined);
  const [loadingImage, setLoadingImage] = useState(undefined);

  const [fullScreenState, setFullScreenState] = useState(false);
  const [seekTime, setSeekTime] = useState(0);
  const [scrubbing, setScrubbing] = useState(false);
  const [scrubTime, setScrubTime] = useState(0);
  const [duration, setDuration] = useState(1);
  const [playEnabled, setPlayEnabled] = useState(true);
  const [pauseEnabled, setPauseEnabled] = useState(true);
  const loadingImages = [loading1, loading2, loading3];

  const handleFullScreenChange = (state, eventHandle) => {
    setFullScreenState(state);
  };

  useEffect(() => {
    if(didMount) {
      setLoadingImage(Math.floor(Math.random() * 2));
      window.AFRAME.registerComponent(`video-player-${uuid}`, {
        init: function () {
          var videoEl = this.el.getAttribute('material').src;
          if (videoEl) setVideoElement(videoEl);
        }
      });
    }
  }, [didMount, uuid, setLoadingImage]);

  const setupVideoEvents = useCallback(() => {
    setPlayerMode('player');
    videoElement.addEventListener('timeupdate',  () => {
      setSeekTime(videoElement.currentTime);
      setDuration(videoElement.duration);
    });
    videoElement.addEventListener('play', () => {
      setPlayEnabled(false);
      setPauseEnabled(true);
    });
    videoElement.addEventListener('pause', () => {
      setPlayEnabled(true);
      setPauseEnabled(false);
    });
    videoElement.addEventListener('ended', props.onEnded);
  });

  const handlePlayButton = () => {
    if(videoElement) {
      videoElement.play();
      setupVideoEvents();
    }
  };

  useEffect(() => {
    if(videoElement && props.autoPlay)
      setupVideoEvents();
  }, [videoElement, setupVideoEvents, props.autoPlay]);

  const handlePauseButton = useCallback(() => {
    if(videoElement) {
      videoElement.pause();
    }
  }, [videoElement]);

  const handleScrubStart = useCallback((value) => {
    setScrubTime(seekTime);
    setScrubbing(true);
  }, [seekTime, setScrubTime, setScrubbing]);
  const handleScrubEnd = useCallback((value) => {
    setScrubbing(false);
    videoElement.currentTime = value;
  }, [setScrubbing, videoElement]);
  const handleScrubChange = useCallback((value) => {
    setScrubTime(value);
  }, [setScrubTime]);

  const playerInnerClasses = classNames(
    'player-inner',
    { active: playerMode === 'player'}
  );

  const playerComponentAttribute = {
    [`video-player-${uuid}`]: `video-player-${uuid}`
  };

  const scrubberValue = scrubbing ? scrubTime : seekTime;
  const {videoDetails, playerStack, currentVideoIndex} = props;

  return (
    <>
      <div className="player-container">
        <h1 className="player-video-title">{videoDetails.name}</h1>
        <div className={playerInnerClasses}>
          <div className="player-video-cover-wrapper">
            <img alt={videoDetails.name} className="player-video-cover-image" src={`${s3_base_url}/${videoDetails.cover_image_url}`} />
            <div className="player-video-cover-overlay">
              {!videoElement && loadingImage !== undefined && (
                <div className="loading-wrapper">
                  <img alt="Loading" src={loadingImages[loadingImage]} className="loading-image" />
                  <span>Loading</span>
                </div>
              )}
              {videoElement && <button onClick={handlePlayButton} ><img alt="Play" src={startButton} /></button>}
            </div>
          </div>
          <FullScreen className="player-full-screen" handle={handle} onChange={handleFullScreenChange}>
            <a-scene class="player-wrapper" vr-mode-ui="false" embedded >
              <a-assets>
                <video autoPlay={!!props.autoPlay} controls={true}  preload="auto" crossOrigin="anonymous" id="360video" loop={false} src={`${playerStack[currentVideoIndex].url}`}></video>;
              </a-assets>
              <a-camera look-controls="reverseMouseDrag: true"></a-camera>
              <a-videosphere {...playerComponentAttribute} autoPlay src="#360video" ></a-videosphere>
            </a-scene>
            { videoElement && playerMode === 'player' && (
              <div className="player-controls">
                <div className="player-scrubber-wrapper">
                  <Scrubber onScrubStart={handleScrubStart} onScrubEnd={handleScrubEnd} onScrubChange={handleScrubChange} min={0} max={duration} value={scrubberValue} />  
                </div>
                <div className="player-controls-buttons">
                  {playEnabled && <button onClick={handlePlayButton} ><img title="Play" alt="Play" src={playButton} /></button>}
                  {pauseEnabled && <button onClick={handlePauseButton} ><img title="Pause" alt="Pause" src={pauseButton} /></button>}
                  <span>{`${Math.floor(seekTime/60)}: ${zeroFill(Math.floor(seekTime % 60),2)}`}   /   {`${Math.floor(duration/60)}: ${zeroFill(Math.floor(duration % 60),2)}`}</span>
                  {!fullScreenState && <button onClick={handle.enter}><img title="Full screen" alt="Full screen" src={fullscreenButton} /></button>}
                  {fullScreenState && <button onClick={handle.exit}><img title="Exit full screen" alt="Exit full screen" src={fullscreenButton} /></button>}
                </div>
              </div> 
            )}
          </FullScreen>
        </div>
      </div>

      <div className="player-video-description">
        <p>{videoDetails.description}</p>
      </div>
    </>
  );
};

export default Player;