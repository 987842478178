import bgHeader from '../../../assets/images/bg-header.svg';
import './styles.scss';

const BackgroundImageHeader = (props) => {
  const style = props.fillColour ? {backgroundColor: props.fillColour} : {};
  return (
    <div className="background-image-header-wrapper" style={style}>
      <img src={bgHeader} alt="" />
      <div className="inner">
        <div className="title-box">
          <h2>Welcome to</h2>
          <h1>HEAD HOLIDAY</h1>
        </div>
        <div className="content">
          { props.children }
        </div>
      </div>
    </div>
  );
};

export default BackgroundImageHeader;