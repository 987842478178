import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import HeaderBar from '../Elements/HeaderBar';
import LoginGate from '../Elements/LoginGate';
import Player from '../Elements/Player';
import Playlist from '../Elements/Playlist';
import { FetchCurrentVideoDetails, FetchPlaylistVideos, catalogueSelectors } from '../../redux/slices/catalogueSlice';
import { CreateStackFromId, playerSelectors } from '../../redux/slices/playerSlice';
import './styles.scss';

const PlayerScreen = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const playerStack = useSelector(playerSelectors.getVideoStack);
  const playlistVideos = useSelector(catalogueSelectors.getPlaylistVideos);
  const fetchedVideoDetails = useSelector(catalogueSelectors.getCurrentVideoDetails);
  const [currentVideoId, setCurrentVideoId] = useState(parseInt(urlParams.videoid));
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoDetails, setVideoDetails] = useState(undefined);
  const paramKeys = Object.keys(urlParams);
  const [playerType, setPlayerType] = useState(paramKeys.includes('playlistid') ? 'playlist' : 'video');
  
  useEffect(() => {
    if(playerType === 'playlist') {
      dispatch(FetchPlaylistVideos(urlParams.playlistid));
    } else {
      dispatch(CreateStackFromId(urlParams.videoid));
    }
  }, [playerType, urlParams, dispatch]);

  useEffect(() => {
    const paramKeys = Object.keys(urlParams);
    setPlayerType(paramKeys.includes('playlistid') ? 'playlist' : 'video');
    setCurrentVideoId(parseInt(urlParams.videoid));
  }, [urlParams]);

  useEffect(() => {
    if(playerType && playlistVideos && currentVideoId) {
      
      const newIndex = playerType === 'playlist' 
        ? playlistVideos.findIndex((video) => {
            return video.id === currentVideoId;
          }) 
        : 0;
      setCurrentVideoIndex(newIndex);

      if(playerType === 'playlist') {
        setVideoDetails(playlistVideos[newIndex]);
      } else {
        dispatch(FetchCurrentVideoDetails(currentVideoId));
      }
    }
  }, [currentVideoId, playerType, playlistVideos, dispatch]);

  useEffect(() => {
    setVideoDetails(fetchedVideoDetails);
  }, [fetchedVideoDetails]);

  const handleVideoEnded = useCallback(() => {
    const nextVideoIndex = currentVideoIndex + 1;
    if(nextVideoIndex < playerStack.length && nextVideoIndex >= 0) {
      const newUrl = `/player/${playerType}/${urlParams.playlistid}/${playlistVideos[nextVideoIndex].id}`;
      history.push(newUrl, {autoPlay: true});
    }
  }, [currentVideoIndex, playerStack, playlistVideos, history, urlParams, playerType]);

  return (
      <>
        <LoginGate loginPath="/login" />
        <HeaderBar colour="purple" />
        <div>
          {videoDetails && playerStack && currentVideoIndex !== -1 && playerStack.length > 0 && playerStack.length > currentVideoIndex && (
            <Player autoPlay={!!(location.state && location.state.autoPlay)} onEnded={handleVideoEnded} videoDetails={videoDetails} playerStack={playerStack} currentVideoIndex={currentVideoIndex} />
          )}
        </div>
        {playerType === 'playlist' && (
          <Playlist playlistId={urlParams.playlistid} playlistVideos={playlistVideos} currentVideoIndex={currentVideoIndex} />
        )}
      </>
  );
};

export default PlayerScreen;