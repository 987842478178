import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import appReducer from './slices/appSlice';
import userReducer from './slices/userSlice';
import catalogueReducer from './slices/catalogueSlice';
import libraryReducer from './slices/librarySlice';
import playerReducer from './slices/playerSlice';

const reducers = combineReducers({
  appReducer,
  userReducer,
  catalogueReducer,
  libraryReducer,
  playerReducer
});

const middleware = process.env.NODE_ENV === 'production' ? [thunk] : [thunk, logger];

const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, reducers)


export const store = configureStore({
    reducer: persistedReducer, 
    middleware: middleware
});

export const persistor = persistStore(store);
