import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import LoginGate from '../Elements/LoginGate';
import BackgroundImageHeader from '../Elements/BackgroundImageHeader';
import HeaderBar from '../Elements/HeaderBar';
import SearchBar from '../Elements/SearchBar';
import FeaturedPlaylistTile from '../Elements/Tiles/FeaturedPlaylistTile';
import GenreTile from '../Elements/Tiles/GenreTile';
import PlaylistTile from '../Elements/Tiles/PlaylistTile';
import { AddButton, RemoveButton } from '../Elements/Buttons';
import { useDidMount } from '../../utilities';
import { userSelectors } from '../../redux/slices/userSlice';
import { FetchHomeCollections, FetchPlaylists, catalogueSelectors } from '../../redux/slices/catalogueSlice';
import './styles.scss';

const renderTileItem = (index, item, tileIndex) => {
  const libraryButton = item.in_library ? <RemoveButton onPress={() => this.props.removePlaylistFromLibrary(item)} /> : <AddButton onPress={() => this.props.addPlaylistToLibrary(item)} />;
  return  (index === 0) 
            ? <FeaturedPlaylistTile key={`feature-tile-${tileIndex}`} button={libraryButton} item={{...item}} /> : 
          (index % 2 === 1) 
            ? <GenreTile key={`genre-tile-${tileIndex}`} buttonAction={(playlist) => playlist.in_library ? this.props.removePlaylistFromLibrary(playlist) : this.props.addPlaylistToLibrary(playlist)} item={{...item}} /> 
            : <PlaylistTile key={`playlist-tile-${tileIndex}`} {...item} />;
}

const Home = (props) => {
  const isLoggedIn = useSelector(userSelectors.getLoggedIn);
  const homeCollections = useSelector(catalogueSelectors.getHomeCollections);
  const didMount = useDidMount();
  const dispatch= useDispatch();

  useEffect(() => {
    if(didMount && isLoggedIn) {
      dispatch(FetchHomeCollections());
      dispatch(FetchPlaylists());
    }
  }, [didMount, isLoggedIn, dispatch]);

  return (
    <>
      <LoginGate loginPath="/login" />
      {isLoggedIn && (
        <>
          <HeaderBar />
          <BackgroundImageHeader>
            <SearchBar />
            <div className="intro-text">
              <p>Head Holiday is a smartphone app (and website) that uses 360 degree videos to change your mood, entertain you, and give you lots of travel ideas.</p>
              <p>Created by a crack team of cultural producers, videographers and travellers, Head Holiday mixes elements of environmental psychology, slow cinema and vlogging. </p>
            </div>
          </BackgroundImageHeader>
          <div className="home-page-collections-wrapper">
            {homeCollections && homeCollections.map((collection, collectionIndex) => {
              let tileData = collection.playlists;
              const collectionClass = classNames(`collection-wrapper-${collection.id}`, 'collection-wrapper');
              return (
                <div key={`collection-${collectionIndex}`} className={collectionClass}>
                  <h1 className="collection-title">{collection.name}</h1>
                  <div className="collection-tiles-wrapper">
                    {tileData.map((tileItem, tileIndex) => {
                      return renderTileItem(collectionIndex, tileItem, tileIndex)
                    })}
                  </div>
                </div>
            ) ;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Home;