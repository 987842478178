import {createSlice} from '@reduxjs/toolkit';

import {FetchActionWithAuth} from '../../utilities';
import * as appActions from './appSlice';
import * as libraryActions from './librarySlice';
import * as userFunctions from '../../api/userFunctions';

const initialState = {
  user: {},  // FROM API
  logged_in: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.user = action.payload;
      state.logged_in = true;
    },
    setLoggedOut: (state, action) => {
      state.user = {};
      state.logged_in = false;
    }
  }
});

export const SendLogin = (email, password) => {
  return (dispatch, getState) => {
    dispatch(appActions.startFetch());
    userFunctions.login(email, password).then((userData) => {
      dispatch(appActions.stopFetch());
      if(userData.token && userData.token.plainTextToken && typeof(userData.token.plainTextToken) === "string" && userData.token.plainTextToken.length > 0)  {
        dispatch(userSlice.actions.setLoggedIn({user: { email }, token: userData.token}));
        dispatch(libraryActions.clearLibrary());
      }
      else 
        dispatch(appActions.setErrorMessage('There was a problem logging in'));
    }).catch((error) => {
      dispatch(appActions.stopFetch());
      dispatch(appActions.setErrorMessage(error.message));
    });
  };
}

export const SendLogout = (email, password) => {
  return (dispatch, getState) => {
    dispatch(userSlice.actions.setLoggedOut());
    dispatch(libraryActions.clearLibrary());
  }
}

export const SendFeedback = (responses, responseId, playlistId, video_id) => {
  return (dispatch, getState) => {
    const postPlaylistId = playlistId ? playlistId : null;
    const postVideoId = !playlistId ? video_id : null;
    const postResponses = responses.map(response => {
      return Object.assign({}, {type: response.key, value: response.value, response_id: responseId}, postPlaylistId !== null ? {playlist_id: postPlaylistId} : {}, postVideoId !== null ? {video_id: postVideoId} : {});
    })
    return FetchActionWithAuth(dispatch, getState, userFunctions.sendFeedback, postResponses, null, null, '');
  };
};

export const userSelectors = {
  getLoggedIn: (state) => {
    return state.userReducer.logged_in;
  },
  getToken: (state) => {
    return state.userReducer.user && state.userReducer.user.token && state.userReducer.user.token.plainTextToken
  }
};

export const {setLoggedIn, setLoggedOut} = userSlice.actions;
export default userSlice.reducer;